import React, { useState } from 'react';
import calculateTrack from './audioCalcTrack'

import style from './audioPlayer.module.scss'

let dots = []
for(let i=0; i<50; i++) dots.push(0)

const AudioVisuals = ({ color, isPlaying, currentTime, duration, handleOnClick, setCurrentTime }) => {

    const [ state, setState ] = useState({
        mouseDown: false
    })


    return(
        <svg 
            viewBox="0 0 200 200" 
            width="100%"
        >
            <mask id="myMask">
                <rect x="0" y="0" width="100" height="100" fill={color || "white"} />
                {/* <circle cx={100} cy={100} r={100} fill="black" /> */}
                <circle cx={100} cy={100} r={50} fill="black" />
            </mask>
            <circle 
                onMouseDown={() => setState({...state, mouseDown: true })}
                onMouseUp={() => setState({...state, mouseDown: false })}
                onMouseEnter={() => setState({...state, mouseDown: false })}
                // onMouseMove={handleMouseMove}
                cx={100} cy={100} r={100} mask="url(#myMask)" fill="transparent" 
            />
            <text x="90" y="27" fill={color || "white"} textAnchor="end">
                {`${Math.floor(currentTime/60)}:${currentTime % 60 < 10 ? "0" : ""}${Math.floor(currentTime % 60)}`}
            </text>
            {
                dots.map((el, i) => {

                    const { x, y, trackPassed, angle } = calculateTrack(dots, i, currentTime, duration)
                    const height = trackPassed ? 10 : 4
                    const width = 0.0001
                  
                    return(
                        <circle 
                            key={i} 
                            fill={color || "white" }
                            cx={100 + x} 
                            cy={100 + y} 
                            r={trackPassed ? 3 : 1} 
                            className={`${trackPassed ? style.scale : ""}`} 
                        />
                    )
                })
            }
            <g className={style.audioPlay}>
                { 
                !isPlaying ?  
                <path fill={color || "white"} d={"M 80 70 L 130 100 L 80 130 L 80 70"} />  
                :
                <path fill={color || "white"} d={"M 80 70 L 90 70 L 90 130 L 80 130 L 80 70 M 110 70 L 120 70 L 120 130 L 110 130 L 110 70"} />
                }
                <circle onClick={handleOnClick} cx={100} cy={100} r={50}  fill={"transparent"}/>
            </g>
        </svg>
    )
}

export default AudioVisuals