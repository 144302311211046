import React, { Suspense, useRef, useState, useEffect } from 'react';
import AudioVisuals from './audioVisualisation'
import style from './audioPlayer.module.scss'
// import { Autoplay } from 'swiper';

let myReq;

const AudioPlayer = ({
    file,
    autoPlay,
    isMuted,
    looped,
    onEnded,
    onPlay,
    color
}) => {
    // AUDIO FILE HANDLEING
    const audioRef = useRef()

    const [ state, setState ] = useState({
        audioPos: 0,
        duration: null,
        currentTime: 0,
        // isPlaying: autoPlay ? true : false,
        isPlaying: false,
        isHovered: false
    })

    let timeout;

    useEffect(() => {
        // console.log('audioplayer use effect', autoPlay, audioRef)
        if(autoPlay && !myReq && handleOnClick) handleOnClick() 
        // if(autoPlay && !myReq) timeout = setTimeout(() => { handleOnClick() }, 200 )

        return () => { 
            if(myReq) cancelAnimationFrame(myReq)
            clearTimeout(timeout)
        }
    }, [])
    
    if(!file || typeof Audio === 'undefined') return false

    const audio = new Audio(file)
    audio.load();
    // audio.onloadeddata(() => {
    //     audioRef.current.play()
    // })

    const handleOnClick = () => {

        // console.log("handle on click", state.isPlaying)

        if(audioRef && audioRef.current.paused){
            // PLAY TUNE
            audioRef.current.play()

            const step = () => {

                if(!audioRef || !audioRef.current || !audioRef.current.paused){ 

                    myReq = requestAnimationFrame(step);

                    setState({
                        ...state,
                        isPlaying: true,
                        currentTime: audioRef.current.currentTime,
                        duration: audioRef.current.duration
                    })
                }
                else cancelAnimationFrame(myReq)
            
            }
            myReq = requestAnimationFrame(step);
    
        }else{
            handlePause()
        } 
    }

    const handlePause = () => {
            // PAUSE TUNE
            audioRef.current.pause()
            cancelAnimationFrame(myReq)

            setState({
                ...state,
                isPlaying: false,
            })
    }

    const setCurrentTime = (currentTime) => {
        // console.log(currentTime)
        // handlePause()
        audioRef.current.currentTime = state.duration * currentTime
        setState({
            ...state, 
            currentTime: state.duration * currentTime
        })
    }

    const getCurrentTime = (currentTime) => {
        setState({
            ...state, 
            currentTime: currentTime
        })
    }

    return(
        <div className={style.audioPlayer} >
            <Suspense fallback={null}>
                <AudioVisuals
                color={color}
                isPlaying={state.isPlaying}
                currentTime={state.currentTime}
                duration={state.duration}
                handleOnClick={handleOnClick}
                setCurrentTime={setCurrentTime}
                />
                <audio 
                    ref={audioRef} 
                    src={file} 
                    looped={`true`}
                
                    onTimeUpdate={(e) => {
                        if(onPlay) onPlay(e)
                        getCurrentTime(e.currentTarget.currentTime)
                        // if(!duration && e.currentTarget && e.currentTarget.duration) setState({...state, duration: e.currentTarget.duration })
                    }}
                    autoPlay={autoPlay}
                    muted={isMuted || false}
                    // onPause={handlePause}
                    // onPlay={onPlay}
                    onEnded={onEnded}
                />
            </Suspense>
        </div>
    )
}

export default AudioPlayer

// class AudioPlayer extends React.PureComponent {

//     constructor(props){
//         super(props)

//         this.audioRef = React.createRef()
//         this.handleOnClick = this.handleOnClick.bind(this)
//         this.state = {
//             audioPos: 0,
//             duration: null,
//             currentTime: 0,
//             isPlaying: false,
//             isHovered: false,
//             audio: (!props.file || typeof Audio === 'undefined') ? false : new Audio(props.file)
//         }
    
//     }
//     // AUDIO FILE HANDLEING
//     handleOnClick() {

//         console.log("handle on click", this.state.isPlaying)
//         if(!this.state.isPlaying){
//             this.audioRef.current.play()
//             this.setState({
//                 ...this.state,
//                 isPlaying: true,
//                 duration: this.audioRef.current.duration
//             })
    
//         }else{
//             this.audioRef.current.pause()

//             this.setState({
//                 ...this.state,
//                 isPlaying: false,
//             })
//             // handlePause()
//         } 
//     }

//     render(){

//         if(!this.audio) return false
        
//         this.audio.load();

//         return(
//             <div className={style.audioPlayer} 
//                 onClick={this.handleOnClick}
//             >
//                 <AudioVisuals isPlaying={this.state.isPlaying} currentTime={this.state.currentTime} duration={this.state.duration}/>
                
//                 <audio 
//                     ref={this.audioRef} 
//                     src={this.file} 
//                     loop 
//                     // onPlay={handlePlay}
//                     // onPause={handlePause}
//                 />
//             </div>
//         )
//     }
   
// }

// export default AudioPlayer
