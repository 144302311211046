const conf = {
    degres: 270,
    radius: 80
}

// const getAngleInRad = () => {

// }

const calculateTrack = (dots, index, currentTime, duration) => {

    // console.log("dots, index, currentTime, duration")    
    // console.log(dots, index, currentTime, duration)
    // Radius
    const distanceToCenter = conf.radius;

    // Calculate Area that Player Covers
    const segAngle = conf.degres / dots.length

    // Convert Degrees to Radiants
    const segRad = segAngle * Math.PI / 180

    // Get Current Segment
    const currentSegment = index+1

    // Get Radiant of Current Segment
    const alpha = segRad * currentSegment - Math.PI/2

    // Get Degrees of Current Segment
    const angle = alpha / Math.PI * 180
    // const angle = segAngle * index

    // Calculate Postion of current Segment
    const x = Math.cos(alpha) * distanceToCenter
    const y = Math.sin(alpha) * distanceToCenter

    // calculate current position in relation to
    const trackPassed = currentTime / duration >= index / dots.length

    return { x, y, trackPassed, angle }
}

export default calculateTrack