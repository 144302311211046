import React, { Suspense, useEffect, useState } from "react"
// import * as THREE from 'three'
import { 
    Canvas, 
    // useThree,
    // useLoader
} from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import AudioPlayer from '../../elements/audioPlayer';


import style from "./helin-ulas.module.scss"

import FontGeometry from './fontGeometry'

const defaultValue = 2

const texts = [
    { 
        text: 'Space is requilutions about the transitions.', 
        length: defaultValue*1
    },
    { 
        text: 'Space is of the vast accounted for a new terms of order of implication', 
        length: defaultValue*1.5
    },
    { 
        text: 'Space is defining from a development - which we were to disappear', 
        length: defaultValue*1.25
    },
    { 
        text: 'Space is pre limits', 
        length: defaultValue*0.2
    },
    { 
        text: 'Space is actional mechanisms', 
        length: defaultValue*0.7
    },
    { 
        text: 'Space is shifted into absolute society', 
        length: defaultValue*0.8
    },
    { 
        text: 'Space is elasticity to a procedure - where one function changes initial logical collection with action of objects', 
        length: defaultValue*2
    },
    { 
        text: 'Space is not to push of a spatial point by violence to lasts', 
        length: defaultValue*1.2
    },
    { 
        text: 'Space is is the gime, it is also no to exploit fact to singlest life', 
        length: defaultValue*1.3
    },
]


const Scene = ({ content }) => {


    //  create Font Files
    const fontFile = content.helinfnt.filename
    const fontAtlas = content.helinpng.filename

    return(
        <>  
            <Suspense fallback={null}>
                <FontGeometry fontFile={fontFile} fontAtlas={fontAtlas} texts={texts}/>
            </Suspense>
            <OrbitControls />
            <ambientLight />
        </>
    )
}


const Helin = ({ content }) => {

    const audioFiles = content.helin_audio

    const [ state, setState ] = useState({
        pixelRatio: null,
        textDisplayed: 0
    })

    let timeout;

    useEffect(() => {

        setState({
            pixelRatio: window.devicePixelRatio || 1
        })
    }, [])

    useEffect(() => {

        timeout = setTimeout(() => {

            setState({
                ...state,
                textDisplayed: state.textDisplayed < texts.length ? state.textDisplayed + 1 : 0
            })
        }, 5000)

        return () => {
            clearTimeout(timeout)
        }
    })

    return(
        <div className={style.helin}>
            {/* {state.pixelRatio && 
                <Canvas
                width="100vw"
                height="100vh"
                camera={{ position: [0, 0, 10], fov: 35 }}
                >
                    <Scene content={content}/>
                </Canvas>
            } */}
            {texts[state.textDisplayed] && <div className={style.info}>
                <div>{texts[state.textDisplayed].text}</div>
            </div>}
            <div className={style.audio}>
            {
                audioFiles.map((file, i) => {

                    return (
                        <div key={i}>
                            <AudioPlayer file={file.filename} autoPlay={i === 0} />
                        </div>
                    )
                })
            }
            </div>
            <style>
                {`
                .canvas{
                    overflow: visible;
                }

                .canvas canvas{
                    position: relative;
                    height: ${100*state.pixelRatio}vh;
                    transform: scale(${1/state.pixelRatio}, ${1/state.pixelRatio});
                    transform-origin: 50% 0;
                }
                `}
            </style>
        </div>
    )
}

export default Helin
