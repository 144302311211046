import React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import Helin from "../../components/pages/helin-ulas/helin-ulas"
import AccessWrapper from '../../components/pages/AccessWrapper'

// console.log(list)

const HelinPage = ({ data }) => {
  const story = data.storyblokEntry
  const content = JSON.parse(story.content)

  return(
    <Layout>
      <SEO title="Home" />
      <AccessWrapper
      introString='Helin Ulas'
      content={content.artists[2]}
      id={2}
      >
        <Helin content={content}/>
      </AccessWrapper>
    </Layout>
  )
}

export default HelinPage



export const query = graphql`
  query {
    storyblokEntry(slug: { eq: "artists" }) {
      content
    }
  }
`